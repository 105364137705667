import React from 'react';
import './scss/App.scss';
import Projects from './components/Projects';
import Navigation from './components/Navigation';

function App() {
  console.log('App');
  return (
    <div className="App">
      <Projects/>
      <Navigation/>
    </div>
  );
}
export default App;