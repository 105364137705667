import React, {Component} from 'react'
import axios from 'axios';
import ProjectButton from './ProjectButton'

export class Menu extends Component {
    
    state = {
       projects: [],
       isLoaded: false
    }

    componentDidMount () {
        console.log('Menu');
        axios.get('https://cms.jeremytani.com/wp-json/wp/v2/projects/')
            .then(res => this.setState({
                projects: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err))
    }

    render() {
        const {projects} = this.state;
        return (
            <div id='menu'>
                {projects.map(project =>
                <ProjectButton key={project.id} project={project} onMenuClick={ this.props.onMenuClick }/>
                )}
            </div>
        );
    }
    }
export default Menu