import React, { Component } from 'react'
import axios from 'axios';
//import ProjectButton from './ProjectButton'
import Background from './Background';
import Menu from './Menu';
import Viewer from './Viewer';



export class Projects extends Component {

    
    state = {
        title:'',
        code:'',
        role:'',
        skills:[],
        excerpt:'',
        featured_media:'',
        video:''
    }

    constructor(props){
        super(props);

        this.viewer = React.createRef();
    }

    componentDidMount () {

        const scope = this;
        axios.get('https://cms.jeremytani.com/wp-json/wp/v2/projects/')
            .then(res => {
                const initialProject = res.data[0];

                console.log('initialProject', initialProject.acf.video);

                scope.setState({
                    title:initialProject.title,
                    code:initialProject.acf.key_code,
                    role:initialProject.acf.role,
                    skills:initialProject.acf.skills,
                    excerpt:initialProject.excerpt,
                    featured_media:initialProject.featured_media,
                    video:initialProject.acf.video
                })

            })
            .catch(err => console.log(err)
        )
    }

    menuClickHandler (evt) {
        console.log('menuClickHandler', evt);

        this.setState({
            title:evt.title,
            code:evt.acf.key_code,
            role:evt.acf.role,
            skills:evt.acf.skills,
            excerpt:evt.excerpt,
            featured_media:evt.featured_media,
            video:evt.acf.video
        });

    }

    render() {
        const projects = this.state;
        
        return (
            <div className='projects'>
                <Background project={projects}/>
                <Viewer project={projects} />
                <Menu project={projects} onMenuClick={ event => this.menuClickHandler(event) }/>

                {/*
                <div>
                    {projects.map(project =>
                    <ProjectButton key={project.id} project={project}/>
                    )}
                </div>
                    */}
            </div>
        );
    }
    }
export default Projects