import React, { Component } from 'react'
import axios from 'axios';

export class Background extends Component {
    
    state = {
        project:{},
        imgUrl:''
    }

    componentDidUpdate(prevProps, prevState){
        console.log('componentDidUpdate', prevProps.project, this.state.project, this.props.project );
        if(prevProps.project.code !== this.props.project.code) {

            const featured_media = this.props.project.featured_media;
            
            const getImageUrl = axios.get(`https://cms.jeremytani.com/wp-json/wp/v2/media/` + featured_media);

            let imgURL = '';

            const scope = this;
        
            Promise.all([getImageUrl]).then(res => {


                imgURL = res[0].data.media_details.sizes.full.source_url;

                scope.setState({
                    project:this.props.project,
                    imgUrl:imgURL
                });
            });
        }
    }

    render() {

        return (
            <div id='background' style={{backgroundImage:'url(' + this.state.imgUrl + ')', backgroundSize:'cover', opacity:0.5}}>
            </div>
        );
        
    }
}
export default Background