import React, { Component } from 'react'
import axios from 'axios';

export class Viewer extends Component {

    state = {
        project:null,
        imgUrl:''
    }

    componentDidMount () {
        this.setState(this.props.project);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.project !== this.state.project) {

            const featured_media = this.props.project.featured_media;
            
            const getImageUrl = axios.get(`https://cms.jeremytani.com/wp-json/wp/v2/media/` + featured_media);

            let imgURL = '';

            const scope = this;

            
        
            Promise.all([getImageUrl]).then(res => {

                if(res[0].data.length > 1){ return; } // For some reason, this is called twice, first time: requests all featured_media from all posts

                imgURL = res[0].data.media_details.sizes.full.source_url;

                scope.setState({
                    project:this.props.project,
                    imgUrl:imgURL
                });
            });
        }
    }

    render() {

        const project = this.props.project;
        const video = 'https://player.vimeo.com/video/' + project.video;
        const imgUrl = this.state.imgUrl;


        return (
            <div id='viewer'>
                <div className="header" id={project.code}>
                    <div className="left">
                        <h1 className="title">{project.title.rendered}</h1>
                    </div>
                    <div className="right">
                        <h2 className="role">{project.role}</h2>
                    </div>
                </div>
                <div className="hero">
                    {( () => {
                        if(project.video === ''){
                            return <div className="bg-img" style={{backgroundImage:'url(' + imgUrl + ')', backgroundSize:'cover', repeat:'no-repeat'}}></div>
                        }else{
                            return <iframe title="vimeo-player" src={video} width="640" height="360" frameBorder="0" allowFullScreen></iframe>
                        }
                    })()}
                </div>
            </div>
        );
    }
}
export default Viewer